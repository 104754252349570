import React from "reactn"
import { graphql, StaticQuery } from 'gatsby';
import Fader from "./fader"

const GlobalHeader = ({ headers }) => {
  return (
    <Fader textArray={headers}></Fader>
  )
}


export default props => (
  <StaticQuery
    query={graphql`
          query GlobalQuery {
            datoCmsGlobalSetting {
              pageHeaders {
                htmltext
              }
            }
          }
          `}
    render={({ datoCmsGlobalSetting }) => <GlobalHeader headers={datoCmsGlobalSetting.pageHeaders} {...props} />}
  />
)

