import React, { useState, useEffect } from 'react'
import './fader.css'
import { Text, Container } from '@chakra-ui/react'

const Fader = ({ textArray }) => {

    const [showData, setShowData] = useState(false)
    const [speed, setSpeed] = useState(2000)
    const [textItem, setTextItem] = useState(0)
    const [fadeProp, setFadeProp] = useState({
        fade: 'fade-out',
    });

    useEffect(() => {
        const timeout = setInterval(() => {
            if (!showData) {
                setShowData(true)                
            }
            if (fadeProp.fade === 'fade-in') {
                setFadeProp({
                    fade: 'fade-out'
                })
            } else {
                setSpeed(4000)
                setTextItem(textItem + 1 == textArray.length ? 0 : textItem + 1)
                setFadeProp({
                    fade: 'fade-in'
                })
            }
        }, fadeProp.fade === 'fade-in' ? speed : 800);

        return () => clearInterval(timeout)
    }, [fadeProp])

    return (
        <Container id="pageHeaderText" align={'center'} variant={'globalHeader'}>
            { showData &&
                <Text className={fadeProp.fade}
                    dangerouslySetInnerHTML={{ __html: textArray[textItem].htmltext }}>
                </Text>
            }
        </Container>
    )
}

export default Fader