import React from "reactn"
import PropTypes from 'prop-types'
import { Box, Flex } from "@chakra-ui/layout"
import Breadcrumbs from "./breadcrumbs"
import Search from "./search"
import { Footer } from "./footer"
import { LayoutMain } from "./layoutMain"
import { SearchBox } from '../components/search/searchBox';
import GlobalHeader from "./globalHeader"
export function Layout({ children, pageContext, location }) {
  const {
    breadcrumb,
    collection,
    isCollectionPage,
    collectionURL
  } = pageContext
  return (
    <Box id="layoutSearch">
      <Flex flexDirection={'column'} minHeight={'100vh'} >
        <GlobalHeader />
        <SearchBox />
        <Breadcrumbs breadcrumb={breadcrumb} />
        <LayoutMain location={location}>
          <Box id="layoutSerchBox" ml={[5, 5, 10, 10]} mr={[5, 5, 5, 5]}>
            {children}
            <Search pageContext={pageContext} location={location} collection={collection} collectionURL={collectionURL} isCollection={isCollectionPage}/>
          </Box>
        </LayoutMain >
      </Flex>
      <Footer />
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumb: PropTypes.object
}

Layout.defaultProps = {
  breadcrumb: {}
}