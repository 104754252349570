import React from "reactn"

import { graphql } from "gatsby"
import { Layout } from "../../components/layoutSearch"
import { Seo } from "../../components/seo"
import Products from "../../components/products"
import { PageContent } from "../../components/pageContent"

export default function Voucher({ location, data: { collection } }) {
  const {
    metaTitle,
    metaDescription,
    image,
    descriptionHtml
  } = collection  
  
  const {
    pageTitle,
    social_image,
    social_images
  } = collection.fields
  return (
    <>
      <Seo metaTitle={metaTitle} metaDescription={metaDescription} socialImage={social_image} socialImages={social_images} og_type={'article'} location={location} />
      <PageContent h1Tag={pageTitle}>
        <Products title={pageTitle} html={descriptionHtml} socialImage={social_image} />
      </PageContent>
    </>
  )
}

Voucher.Layout = Layout

export const query = graphql`
  query($id: String!) {
    collection: shopifyCollection(id: { eq: $id }) {
      internal{
        type
      }
      fields {
        pageTitle
        social_image
        social_images
      }
      metaTitle
      metaImage
      metaDescription
      descriptionHtml
      image {
        gatsbyImageData(
          width: 100
        )
      }
    }
  }
`
