import React from 'reactn'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react"
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Link } from 'gatsby'

const Breadcrumbs = ({ breadcrumb }) => {
  const { crumbs } = breadcrumb

  return (
    <>
      {crumbs.length > 1 ?
        <Breadcrumb id="breadcrumb" marginTop={4} fontSize={12} textAlign={'center'} textTransform={'uppercase'} spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
          {crumbs.map((crumb,index) => (
            <BreadcrumbItem key={crumb.pathname}>
              <BreadcrumbLink pointerEvents={index + 1 < crumbs.length ? 'unset' : 'none'} as={Link} to={crumb.pathname.replace(/\/$/, '')}>{crumb.crumbLabel}</BreadcrumbLink>
            </BreadcrumbItem>
          ))}

        </Breadcrumb>
        : <></>
      }
    </>
  )
}

export default Breadcrumbs
