import React from "reactn"
import PropTypes from 'prop-types'
import { Box, Heading } from "@chakra-ui/layout"
import { VisuallyHidden } from '@chakra-ui/react'

export function PageContent({ h1Tag, children }) {
  
  const suffix = " | INGMARSON"

  return (
    <Box id="pageContent">
      <VisuallyHidden>
        <Heading as="h1">{h1Tag}{suffix}</Heading>
      </VisuallyHidden>
      {children}  
    </Box>
  )
}

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  h1Tag: PropTypes.string.isRequired
}

PageContent.defaultProps = {
}
